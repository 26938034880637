// app.js

import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';

import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';

import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

import imageIcon from '@ckeditor/ckeditor5-core/theme/icons/image.svg';

import fileIcon from "/icon-files.svg"
import videoIcon from "/icon-videos.svg"

import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import {Heading} from "@ckeditor/ckeditor5-heading";
import {Link} from "@ckeditor/ckeditor5-link";
import {ListStyle} from "@ckeditor/ckeditor5-list";
import {Font} from "@ckeditor/ckeditor5-font";
import {Highlight} from "@ckeditor/ckeditor5-highlight";
import {Indent, IndentBlock} from "@ckeditor/ckeditor5-indent";
import {ImageInsert, ImageResizeEditing, ImageResizeHandles, ImageUpload} from "@ckeditor/ckeditor5-image";
import {BlockQuote} from "@ckeditor/ckeditor5-block-quote";
import {Table, TableToolbar} from "@ckeditor/ckeditor5-table";
import {MediaEmbed} from "@ckeditor/ckeditor5-media-embed";
import {Alignment} from "@ckeditor/ckeditor5-alignment";
import {SourceEditing} from "@ckeditor/ckeditor5-source-editing";

class StorageInsertImage extends Plugin {
    init() {
        const editor = this.editor;
        editor.ui.componentFactory.add( 'storageInsertImage', locale => {
            const view = new ButtonView( locale );

            view.set( {
                label: 'Insert image',
                icon: imageIcon,
                tooltip: true
            } );

            view.on( 'execute', () => {
                showStorageWidget('image', 'insertImageToEditor', this.editor.id);
            });

            return view;
        } );
    }
};

class StorageInsertVideo extends Plugin {
    init() {
        const editor = this.editor;
        editor.ui.componentFactory.add( 'storageInsertVideo', locale => {
            const view = new ButtonView( locale );

            view.set( {
                label: 'Insert video',
                icon: videoIcon,
                tooltip: true
            } );

            view.on( 'execute', () => {
                showStorageWidget('video', 'insertVideoToEditor', this.editor.id);
            });

            return view;
        } );
    }
};

class StorageInsertFile extends Plugin {
    init() {
        const editor = this.editor;
        editor.ui.componentFactory.add( 'storageInsertFile', locale => {
            const view = new ButtonView( locale );

            view.set( {
                label: 'Insert file',
                icon: fileIcon,
                tooltip: true
            } );

            view.on( 'execute', () => {
                showStorageWidget('file', 'insertFileToEditor', this.editor.id);
            });

            return view;
        } );
    }
};

console.log('initialized');
window.initCFKEditor = function (selector) {
    console.log('called');
    ClassicEditor
        .create(document.querySelector(selector), {
            plugins: [SourceEditing, Essentials, Paragraph, Bold, Italic, Alignment, Image, ImageToolbar, ImageCaption, ImageStyle, ImageResizeEditing, ImageResizeHandles, Heading, Link, ListStyle, Font, Highlight, Indent, ImageUpload, ImageInsert, BlockQuote, Table, TableToolbar, MediaEmbed, StorageInsertFile, StorageInsertImage, StorageInsertVideo],
            toolbar: {
                items: [
                    'heading',
                    '|',
                    'bold',
                    'italic',
                    'link',
                    'bulletedList',
                    'numberedList',
                    'fontColor',
                    'fontBackgroundColor',
                    'highlight',
                    'alignment',
                    '|',
                    'indent',
                    'outdent',
                    '|',
                    // 'insertImage',
                    'blockQuote',
                    'insertTable',
                    'mediaEmbed',
                    'undo',
                    'redo',
                    '|',
                    'storageInsertImage',
                    'storageInsertVideo',
                    'storageInsertFile',
                    'sourceEditing'
                ]
            },
            language: 'pl',
            link: {
                decorators: {
                    openInNewTab: {
                        mode: 'manual',
                        label: 'Open in a new tab',
                        attributes: {
                            target: '_blank',
                            rel: 'noopener noreferrer'
                        }
                    }
                }
            },
            image: {
                toolbar: [
                    'imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight'
                ],
                styles: [
                    'alignLeft',
                    'alignCenter',
                    'alignRight'
                ]
            },
            table: {
                contentToolbar: [
                    'tableColumn',
                    'tableRow',
                    'mergeTableCells'
                ],
                customClass: 'table table-bordered'
            },
            mediaEmbed: {
                previewsInData: true,
                extraProviders: [
                    {
                        name: 'storage',
                        url: [
                            /storage\/(.*)/,
                        ]
                    }
                ]
            },
        })
        .then(editor => {
            console.log( 'Editor was initialized', editor );
            window.editor[editor.id] = editor;
        })
        .catch(error => {
            console.error(error.stack);
        });
};
